<!--  -->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
#app {
  // 滚动条整体宽度
  ::-webkit-scrollbar {
    width: 8px;
  }
  // 滚动条滑槽样式
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
  }
  // 滚动条样式
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #ddd;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
}
</style>
