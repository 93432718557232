import Vue from "vue";
import VueRouter from "vue-router";
import home from '../views/index.vue'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter);

const routes = [
    { path: "/", redirect: "/home" },
    {
        path: "/home",
        name: "home",
        meta: {},
        component: () =>
            import ("../views/choseView.vue"),
    },
    {
        path: "/index",
        name: "index",
        meta: {},
        component: home,
        children: [
            {
                path: "/homePage",
                name: "homePage",
                meta: {},
                component: () =>
                    import ("../views/homePage.vue"),
            },
            {
                path: "/secondPage",
                name: "secondPage",
                meta: {},
                component: () =>
                    import ("../views/secondPage.vue"),
            },
            {
                path: "/threePage",
                name: "threePage",
                meta: {},
                component: () =>
                    import ("../views/threePage.vue"),
            },
            {
                path: "/searchPage",
                name: "searchPage",
                meta: {},
                component: () =>
                    import ("../views/searchPage.vue"),
            },
        ]
    },
    {
        path: "/phoneIndex",
        name: "phoneIndex",
        meta: {},
        component: () =>
            import ("../views/phoneIndex.vue"),
    },
    {
        path: "/pageInfo",
        name: "pageInfo",
        meta: {},
        component: () =>
            import ("../views/pageInfo.vue"),
    },
];
const router = new VueRouter({
    routes,
});

export default router;