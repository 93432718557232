import axios from "axios";
import QS from "qs";
import router from "../router/index";
import { Message } from "element-ui";
var timer = true;
const http = axios.create({
    timeout: 30000,
    baseURL: process.env.NODE_ENV=='production' ? '/api':'',
    withCredentials: true,
});
//添加请求拦截器
http.interceptors.request.use(
    function(config) {
        //在发送请求之前做某事
        let token = sessionStorage.getItem("token");
        if (token) config.headers["session"] = token;
        return config;
    },
    function(error) {
        //请求错误时做些事
        return Promise.reject(error);
    }
);
//添加响应拦截器
http.interceptors.response.use(
    function(response) {
        //对响应数据做些事
        if (response.data.resCode === 1040) {
            console.log("错误");
        } else {
            //文件导出
            if (response.headers["content-disposition"]) {
                return response;
            } else {
                return response.data;
            }
        }
    },
    function(error) {
        //请求错误时做些事
        if (error.response.status == 401) {
            if (timer) {
                router.push("/login");
                Message({
                    type: "error",
                    message: "登陆过期，请重新登陆！",
                });
                timer = false;
                setTimeout(() => {
                    timer = true;
                }, 2000);
            }
        } else {
            Message({
                type: "error",
                message: error.response.data.detail,
            });
        }
        return Promise.reject(error.response.data);
    }
);
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        http
            .get(url, { params })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        http
            .post(url, params)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
/**
 * put方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function put(url, params) {
    return new Promise((resolve, reject) => {
        http
            .put(url, params)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
/**
 * patch方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function patch(url, params) {
    return new Promise((resolve, reject) => {
        http
            .patch(url, params)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
/**
 * delete方法，对应delete请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function dele(url, params) {
    return new Promise((resolve, reject) => {
        http
            .delete(url, params)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
/**
 * 文件下载
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function download(url, params) {
    return new Promise((resolve, reject) => {
        http({
                method: "post",
                url: url,
                data: params,
                responseType: "blob",
            })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
export default http;