import { get, post, put, dele } from "./http";
//添加留言
export function questbook(params) {
  return post("/user/guestbook", params);
}
//获取标题
export function head_list() {
  return get("/user/first_title/head_list");
}
//获取一级标题
export function first_title(id) {
  return get(`/user/first_title/${id}`);
}
//根据id获取文章
export function get_article(id) {
  return get(`/user/articles/${id}`);
}
//根据二级标题获取文章列表
export function get_title_article(id) {
  return get(`/user/articles?category=${id}`);
}
//模糊搜索
export function search_key(key) {
  return get(`/user/articles_search?search=${key}`);
}
//作者或者标题搜索
export function search_flag(params) {
  return get(`/user/articles`,params);
}
//模糊搜索数目
export function search_book(key) {
  return get(`/user/books_search?search=${key}`);
}
//按条件搜索书目
export function book_flag(params) {
  return get(`/user/books`,params);
}
//获取书目列表
export function getBooks() {
  return get(`/user/books`);
}

