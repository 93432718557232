<!-- 首页 -->
<template>
  <div class="index">
    <div class="header">
      <div class="headContent">
        <ul>
          <li @click="$router.push('/homePage')">
            <img src="../assets/1-贵溪市图书馆 LOGO.png" alt="" />
          </li>
          <li
            v-for="tab in tabList"
            :key="tab.id"
            @click="active(tab)"
            :class="{ active: activeTab === tab.id }"
          >
            <el-dropdown v-if="tab.children.length > 0" @command="handleCommand">
              <span class="el-dropdown-link">
                {{ tab.title }}
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="item in tab.children"
                  :key="item.id"
                  :command="{ tab, item }"
                >
                  {{ item.title }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <span class="el-dropdown-link" v-else>
              {{ tab.title }}
            </span>
          </li>
        </ul>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { head_list } from "../request/apis";
import { toSecend, toPage } from "../utils/util";
export default {
  components: {},
  data() {
    return {
      tabList: [],
      activeTab: "",
    };
  },
  computed: {},
  watch: {},
  methods: {
    active(tab) {
      this.activeTab = tab.id;
      //点击资源检索回到首页面
      if (tab.id == 1) {
        this.$router.push({ path: "/homePage" });
      } else {
        let params = {
          firstId: tab.id,
        };
        //跳转至二级首页
        this.$router.push({ path: "/secondPage", query: params });
      }
    },
    //获取标题
    head_list() {
      head_list().then((res) => {
        this.tabList = res;
      });
    },
    handleCommand(v) {
      //跳转至二级首页
      let params = {
        firstId: v.tab.id,
        secondId: v.item.id,
      };
      this.$router.push({ path: "/secondPage", query: params });
    },
  },
  created() {
    this.head_list();
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.header {
  height: 70px;
  width: 100vw;
  display: flex;
  justify-content: center;
  background-color: rgb(0, 123, 203);
  .headContent {
    height: 100%;
    width: 1100px;
    display: flex;
    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      li {
        font-size: 22px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 100%;
        padding: 0px 6px;
        &:hover {
          background-color: rgb(4, 56, 94);
        }
        img {
          height: 94px;
        }
        /deep/ .el-dropdown-link {
          font-size: 26px;
          color: #fff;
        }
      }
      .active {
        background-color: rgb(4, 56, 94);
      }
    }
  }
}
@media screen {
  @media (max-width: 600px) {
    .header {
      overflow-x: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      .headContent {
        width: 100%;
        img {
          height: 90% !important;
        }
        ul {
          li {
            flex-shrink: 0;
            .el-dropdown-link {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
</style>
