import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "../src/style/reset.less";
import "../src/style/element.less";
import "./fonts/font.css";
import "./style/theme/index.css";
//import "amfe-flexible";
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
